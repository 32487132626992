/* global _ */

/**
 * Show/hides the relevant payment methods depending on the selected price.
 *
 * @param {Object} price Selected price option.
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
export function onChangePrice( price, spFormElem, formData ) {
	const { currency: priceCurrency, recurring, can_recur: canRecur } = price;
	const { paymentMethods } = formData;

	const supportedCurrencyPaymentMethods = paymentMethods
		.filter( ( { currencies } ) => {
			return currencies.includes( priceCurrency );
		} );

	const supportedCurrencyPaymentMethodIds = _.pluck(
		supportedCurrencyPaymentMethods, 'id'
	);

	const supportedRecurringPaymentMethods = paymentMethods
		.filter( ( { recurring } ) => {
			return true === recurring;
		} );

	const supportedRecurringPaymentMethodIds = _.pluck(
		supportedRecurringPaymentMethods, 'id'
	);

	const paymentMethodToggles = spFormElem[0].querySelectorAll(
		'.simpay-payment-method-toggle'
	);

	const selectedPaymentMethod = spFormElem[0].querySelector(
		'.simpay-payment-method-toggle.is-active'
	);

	// Show/hide relevant methods.
	[ ...paymentMethodToggles ].forEach( ( paymentMethodToggle ) => {
		const paymentMethodId = paymentMethodToggle.dataset.paymentMethod;

		const supportsCurrency = supportedCurrencyPaymentMethodIds.includes(
			paymentMethodId
		);

		const supportsRecurring = supportedRecurringPaymentMethodIds.includes(
			paymentMethodId
		);

		if ( supportsCurrency ) {
			paymentMethodToggle.style.display = 'block';

			// Check for Subscriptions.
			if (
				( null !== recurring && false === canRecur ) &&
				false === supportsRecurring
			) {
				paymentMethodToggle.style.display = 'none';
			}

			// On the fly check for Recurring Toggle.
			const recurringAmountToggleInput = spFormElem[0].querySelector(
				'.simpay-recurring-amount-toggle'
			);

			if (
				(
					recurringAmountToggleInput &&
					true === recurringAmountToggleInput.checked
				) &&
				false === supportsRecurring
			) {
				paymentMethodToggle.style.display = 'none';
			}
		} else {
			paymentMethodToggle.style.display = 'none';
		}
	} );

	// If the previously selected method is no longer visible, select the first method.
	if ( 'none' === selectedPaymentMethod.style.display ) {
		spFormElem[0].querySelector( '.simpay-payment-method-toggle' ).click();
	}
}

/**
 * Bind events to Payment Form.
 */
$( document.body ).on(
	'simpayBindCoreFormEventsAndTriggers',
	// eslint-disable-line no-unused-vars
	( e, spFormElem, formData ) => {
		if ( ! spFormElem[0].querySelector( '.simpay-card-container' ) ) {
			return;
		}

		// Update amount when a price option selection changes.
		// eslint-disable-line no-unused-vars
		spFormElem.on( 'simpayMultiPlanChanged', ( e, price ) => {
			onChangePrice( price, spFormElem, formData );
		} );

	}
);