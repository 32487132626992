/* global simplePayForms, spGeneral, jQuery, Stripe */

/**
 * Internal dependencies.
 */
import { default as simpayAppProCompat } from './compat.js';
import { getPaymentForms } from '@wpsimplepay/pro/frontend/payment-forms';

import './payment-forms/stripe-elements/overlays.js';
import './components/total-amount-labels.js';
import './components/quantity.js';
import './components/payment-request-button.js';
import './components/address.js';
import './components/date.js';
import './components/tabs.js';
import './components/custom-amount.js';
import './components/recurring-amount-toggle.js';
import './components/payment-methods.js';
import './components/price-select.js';

let simpayAppPro;

( function( $ ) {
	'use strict';

	const body = $( document.body );

	/**
	 * Manage additional "Pro" functionality.
	 *
	 * This object mainly serves as a backwards compatibility shim.
	 */
	simpayAppPro = {
		// Manage multiple payment request buttons.
		paymentRequestButtons: {},

		/**
		 * Setup Payment Forms.
		 */
		init() {
			const stripeElements = getPaymentForms()['stripe-elements'];
			const { setup: setupPaymentForm } = stripeElements;

			// Setup the payment form.
			body.on( 'simpayCoreFormVarsInitialized', setupPaymentForm );

			body.on(
				'simpayBindCoreFormEventsAndTriggers',
				( e, spFormElem ) => {
					this.setOnFieldFocus( spFormElem );
				}
			);

			body.on( 'simpayFinalizeCoreAmount', this.updateAmounts );
		},

		/**
		 * Toggle `is-focused` class on fields to allow for extra CSS styling.
		 *
		 * @param {jQuery} spFormElem Form element jQuery object.
		 * @param {Object} formData Configured form data.
		 */
		setOnFieldFocus( spFormElem, formData ) {
			const fields = spFormElem.find( '.simpay-form-control' );

			fields.each( function( i, el ) {
				const field = $( el );

				field.on( 'focusin', setFocus );
				field.on( 'focusout', removeFocus );

				/**
				 * Add `is-focused` class.
				 *
				 * @param {Event} e Event focusin event.
				 */
				function setFocus( e ) {
					spFormElem.isDirty = true;
					$( e.target ).addClass( 'is-focused' );
				}

				/**
				 * Remove `is-focused` class.
				 *
				 * @param {Event} e Event focusout event.
				 */
				function removeFocus( e ) {
					const $el = $( e.target );

					// Wait for DatePicker plugin
					setTimeout( function() {
						$el.removeClass( 'is-focused' );

						if ( field.val() ) {
							$el.addClass( 'is-filled' );
						} else {
							$el.removeClass( 'is-filled' );
						}
					}, 300 );
				}
			} );
		},

		/**
		 * Calculate payment amounts.
		 *
		 * @param {Event} e Mixed events. Not used.
		 * @param {jQuery} spFormElem Form element jQuery object.
		 * @param {Object} formData Configured form data.
		 */
		updateAmounts( e, spFormElem, formData ) {
			const {
				convertToDollars,
				debugLog,
			} = window.spShared;

			try {
				const {
					cart,
				} = spFormElem;

				const total = cart.getTotal();

				// Backwards compat.
				formData.finalAmount = convertToDollars( total );
				formData.stripeParams.amount = total;

				// Set the same cents value to hidden input for later form submission.
				spFormElem.find( '.simpay-amount' ).val( total );

				// Convert amount to dollars, as the server still expects this.
				spFormElem.find( '.simpay-tax-amount' ).val(
					convertToDollars( cart.getTax() )
				);
			} catch ( error ) {
				debugLog( error );
			}
		},

		...simpayAppProCompat,
	};

	simpayAppPro.init();
}( jQuery ) );

window.simpayAppPro = simpayAppPro;

export default simpayAppPro;
