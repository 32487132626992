/**
 * WordPress dependencies.
 */
import { addQueryArgs } from '@wordpress/url';

/**
 * Internal dependencies.
 */
import { onPaymentFormError } from '@wpsimplepay/core/frontend/payment-forms';
import { create as createCustomer } from '@wpsimplepay/core/frontend/payments/customer.js';
import {
	create as createPaymentIntent,
	handleServerResponse as handlePaymentIntentServerResponse,
} from '@wpsimplepay/pro/frontend/payments/paymentintent.js';
import {
	create as createSubscription,
	handleServerResponse as handleSubscriptionServerResponse,
} from '@wpsimplepay/pro/frontend/payments/subscription.js';

/**
 * Handle a Source.
 *
 * @since 3.8.0
 *
 * @param {Object} source Stripe Source object.
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
export async function handle( source, spFormElem, formData ) {
	try {
		// Create a Customer (and attach Source).
		const customer = await createCustomer(
			{
				source_id: source.id,
			},
			spFormElem,
			{
				...formData,
				...spFormElem.__experimentalFormData,
			}
		);

		// Define a Return URL.
		const successUrl = addQueryArgs( formData.stripeParams.success_url, {
			customer_id: customer.id,
		} );

		let paymentIntentRequiresAction;

		const { isSubscription, isRecurring } = spFormElem.__experimentalFormData;

		// Handle Subscriptions.
		if ( isSubscription || isRecurring ) {
			const subscription = await createSubscription(
				{
					customer_id: customer.id,
				},
				spFormElem,
				{
					...formData,
					...spFormElem.__experimentalFormData,
				}
			);

			// Handle next actions on Subscription's PaymentIntent.
			paymentIntentRequiresAction = await handleSubscriptionServerResponse(
				subscription,
				spFormElem,
				{
					...formData,
					...spFormElem.__experimentalFormData,
				}
			);

			// Handle one-time payments.
		} else {
			const paymentIntent = await createPaymentIntent(
				{
					customer_id: customer.id,
					payment_method_id: source.id,
					payment_method_type: 'card',
				},
				spFormElem,
				{
					...formData,
					...spFormElem.__experimentalFormData,
				}
			);

			// No SCA needed, redirect.
			if ( ! paymentIntent.requires_action ) {
				return ( window.location.href = successUrl );
			}

			// Handle next actions on PaymentIntent.
			paymentIntentRequiresAction = await handlePaymentIntentServerResponse(
				{
					customer_id: customer.id,
					payment_intent: paymentIntent,
				},
				spFormElem,
				{
					...formData,
					...spFormElem.__experimentalFormData,
				}
			);
		}

		// Nothing else is needed, redirect.
		if ( false === paymentIntentRequiresAction ) {
			return ( window.location.href = successUrl );
		}
	} catch ( error ) {
		onPaymentFormError( error, spFormElem, formData );
	}
}
