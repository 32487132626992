/**
 * External dependencies.
 */
import serialize from 'form-serialize';

/**
 * WordPress dependencies.
 */
import { addQueryArgs } from '@wordpress/url';

/**
 * Internal dependencies.
 */
import { plaidHandler } from './setup.js';

import { apiRequest } from '@wpsimplepay/utils';
import { onPaymentFormError } from '@wpsimplepay/core/frontend/payment-forms';
import { create as createCustomer } from '@wpsimplepay/core/frontend/payments/customer.js';
import { create as createCharge } from '@wpsimplepay/pro/frontend/payments/charge.js';
import {
	create as createSubscription,
	handleServerResponse as handleSubscriptionServerResponse,
} from '@wpsimplepay/pro/frontend/payments/subscription.js';

const { enableForm } = window.simpayApp;

/**
 * Submit `ach-debit` Payment Method.
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
export async function submit( spFormElem, formData ) {
	try {
		const achDebitEl = spFormElem[ 0 ].querySelector( '.simpay-ach-debit-wrap' );
		const { publicToken, accountId } = achDebitEl.dataset;

		// Do nothing if a Public Token has not been created.
		if ( ! publicToken ) {
			enableForm( spFormElem, formData );
			return plaidHandler.open();
		}

		const { stripe_bank_account_token: source } = await apiRequest( 'v2/plaid-token', {
			form_values: serialize( spFormElem[ 0 ], { hash: true } ),
			form_data: formData,
			form_id: formData.formId,
			public_token: publicToken,
			account_id: accountId,
		} );

		// Create a Customer (and attach Source).
		const customer = await createCustomer(
			{
				source_id: source,
			},
			spFormElem,
			formData
		);

		// Define a Return URL.
		const successUrl = addQueryArgs( formData.stripeParams.success_url, {
			customer_id: customer.id,
		} );

		// Handle Subscriptions.
		if ( formData.isSubscription || formData.isRecurring ) {
			const subscription = await createSubscription(
				{
					customer_id: customer.id,
				},
				spFormElem,
				{
					...formData,
					...spFormElem.__experimentalFormData,
				}
			);

			// Handle next actions on Subscription's PaymentIntent.
			const paymentIntentRequiresAction = await handleSubscriptionServerResponse(
				subscription,
				spFormElem,
				formData
			);

			// Nothing else is needed, redirect.
			if ( false === paymentIntentRequiresAction ) {
				return ( window.location.href = successUrl );
			}

		// Handle one-time payments.
		} else {
			await createCharge(
				{
					customer_id: customer.id,
				},
				spFormElem,
				{
					...formData,
					...spFormElem.__experimentalFormData,
				}
			);

			return ( window.location.href = successUrl );
		}
	} catch ( error ) {
		onPaymentFormError( error, spFormElem, formData );
	}
}
