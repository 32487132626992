/* global _ */

/**
 * External dependencies.
 */
import serialize from 'form-serialize';

/**
 * Internal dependencies
 */
import { apiRequest } from '@wpsimplepay/utils';
import { onPaymentFormError } from '@wpsimplepay/core/frontend/payment-forms';
import { getPaymentMethod } from '@wpsimplepay/pro/frontend/payment-methods';

const { hooks } = window.wpsp;
const { convertToDollars, formatCurrency } = window.spShared;

export let plaidHandler;

/**
 * Sets up a Stripe Elements iDEAL field and binds events.
 *
 * @link https://stripe.com/docs/stripe-js/reference#elements-create
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
export function setup( spFormElem, formData ) {
	const { cart } = spFormElem;
	const achDebit = getPaymentMethod( 'ach-debit' );
	const achDebitEl = spFormElem[ 0 ].querySelector( '.simpay-ach-debit-wrap' );

	if ( ! achDebitEl ) {
		return;
	}

	const { isTestMode, companyName } = formData;
	const checkoutButtonEl = spFormElem[ 0 ].querySelector( '.simpay-checkout-btn-container' );

	// Handle toggling Checkout and ACH buttons.
	const tabs = spFormElem[ 0 ].querySelectorAll( '.simpay-form-tabs-toggles__toggle' );

	/**
	 * @param {Object} e Tab toggle click event.
	 */
	const maybeHideCheckoutButton = ( { target } ) => {
		checkoutButtonEl.style.display = 'simpay-payment-method-toggle-ach-debit' === target.id
			? 'none'
			: 'block';
	}

	if ( tabs ) {
		// Add a click event handler to each tab.
		_.each( tabs, ( tab ) => tab.addEventListener( 'click', maybeHideCheckoutButton ) );

		// On load.
		maybeHideCheckoutButton( {
			target: tabs[ 0 ],
		} );
	}

	try {
		/**
		 * Creates a Plaid Link token.
		 *
		 * @return {Object}
		 */
		const fetchLinkToken = async () => {
			const { link_token: linkToken } = await apiRequest( 'v2/plaid-token/create-link-token', {
				form_values: serialize( spFormElem[ 0 ], { hash: true } ),
				form_data: formData,
				form_id: formData.formId,
			} );

			return linkToken;
		}

		/**
		 * Handles successful bank account authorization via Plaid Link.
		 *
		 * @param {String} publicToken Public token.
		 * @param {Object} metadata Metadata.
		 */
		const onSuccess = ( publicToken, { account_id: accountId } ) => {
			// Toggle buttons.
			achDebitEl.style.display = 'none';
			checkoutButtonEl.style.display = 'block';

			// Stop hiding Checkout button
			_.each( tabs, ( tab ) => tab.removeEventListener( 'click', maybeHideCheckoutButton ) );

			// Attach data.
			achDebitEl.dataset.publicToken = publicToken;
			achDebitEl.dataset.accountId = accountId;
		};

		/**
		 * Handles closure of Plaid Link.
		 *
		 * @param {String} publicToken Public token.
		 * @param {Object} metadata Metadata.
		 */
		const onExit = ( error, metadata ) => {
			plaidHandler.destroy();

			// The user exited the Link flow.
			if ( error !== null ) {
				onPaymentFormError( error, spFormElem, formData );
			}
		}

		const configs = {
			env: true === isTestMode ? 'sandbox' : 'production',
			clientName: companyName,
			product: [
				'auth',
			],
			selectAccount: true,
			onSuccess,
			onExit,
		};

		/**
		 * "Select Bank" button click handler.
		 *
		 * @param {Object} e Click event.
		 */
		achDebitEl.addEventListener( 'click', async ( e ) => {
			e.preventDefault();

			try {
				// Setup handler.
				// Created on the fly to avoid issues with multiple on the same page.
				plaidHandler = Plaid.create( {
					token: await fetchLinkToken(),
					...configs,
				} );

				plaidHandler.open();
			} catch ( error ) {
				onPaymentFormError( error, spFormElem, formData );
			}
		} );

		const submitEl = achDebitEl.querySelector( 'button' );

		if ( ! submitEl ) {
			return;
		}

		/**
		 * "Select Bank" button keypress handler.
		 *
		 * @param {Object} e Keyup event.
		 */
		submitEl.addEventListener( 'keyup', ( e ) => {
			if ( 13 !== e.keyCode ) {
				return;
			}

			e.preventDefault();
			plaidHandler.open();
		} );

	} catch ( error ) {
		onPaymentFormError( error, spFormElem, formData );
	}
}

/**
 * Bind events to Payment Form.
 */
$( document.body ).on(
	'simpayBindCoreFormEventsAndTriggers',
	( e, spFormElem ) => {
		const { cart } = spFormElem;
		const achDebitButton = spFormElem[ 0 ].querySelector(
			'.simpay-ach-debit-wrap button'
		);

		if ( ! achDebitButton ) {
			return;
		}

		// Update button amount when a price option selection changes.
		spFormElem.on( 'simpayMultiPlanChanged', () => {
			const total = formatCurrency(
				cart.isZeroDecimal()
					? cart.getTotal()
					: convertToDollars( cart.getTotal() ),
				true,
				cart.getCurrencySymbol(),
				cart.isZeroDecimal()
			);

			achDebitButton.querySelector( 'span' ).innerHTML = total;
		} );

    }
);